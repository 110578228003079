import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import PageTitle from '../components/title/PageTitle'
import Star from '../assets/svgs/star.svg'//Star should go off
import ArrowRight from '../assets/svgs/arrow-right.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle, faChevronRight } from '@fortawesome/fontawesome-free-solid'
import DashboardMockup from '../assets/images/browser.png'
import LightBulb from '../assets/svgs/lightbulb.svg'
import ChatScreen from '../assets/images/mockups/chat+screen.webp'
import TextScreen from '../assets/images/mockups/text+screen.webp'
import EngagementIcon from '../assets/svgs/engagement-icon.svg'
import ResolutionIcon from '../assets/svgs/list-checks.svg'
import TrophyIcon from '../assets/svgs/trophy.svg'
import Lines from '../assets/svgs/lines.svg'
import HandsOnMockup from '../assets/images/mockups/hands-on.webp'
import UniqueLink from '../assets/images/mockups/unique+link.webp'
import QrcodeVideo from '../assets/video/scan+qrcode.mp4'
import UnassignVid from '../assets/video/Unassign3.mp4'
import UpdateStatus from '../assets/video/update+status.webm'
import AnalyseIcon from '../assets/svgs/analyse-icon.svg'
import Mockup01 from '../assets/images/mockup01.webp'
import Mockup04 from '../assets/images/mockups/mockup04.webp'
import Accordion from '../components/Accordion'
import Waitlist from '../components/modals/Waitlist'
import Close from '../assets/svgs/close.svg'
import Ihvn from '../assets/images/clients/ihvn.png'
import Ditto from '../assets/images/clients/ditto.png'
import Firmcare from '../assets/images/clients/firmcare.png'
import Valuejet from '../assets/images/clients/valuejet.png'
import pfp1 from '../assets/images/clients/pfp/pfp1.svg'
import pfp2 from '../assets/images/clients/pfp/pfp2.webp'
import pfp3 from '../assets/images/clients/pfp/pfp3.webp'
import pfp4 from '../assets/images/clients/pfp/pfp4.svg'
import pfp5 from '../assets/images/clients/pfp/pfp5.webp'
import pfp6 from '../assets/images/clients/pfp/pfp6.webp'
import ussdMockup from '../assets/images/mockups/ussd.webp'
import whatsappMockup from '../assets/images/mockups/whatsapp+flow.webm'


const Home = () => {
    // const LazyStar = React.lazy(() => import('../assets/svgs/star.svg')) 

    const screenY = window.innerHeight
    const [channel, setChannel] = useState(0)
    const [engagement, setEngagement] = useState(0)
    const [shortenUrl, setshortenUrl] = useState(false)
    const [bgVideo, setbgVideo] = useState(0)
    const [modal, setModal] = useState(false);
    const closeModal = () => setModal(!modal) //close modal

    const [industry, setIndustry] = useState(0);
    const [popup, setPopup] = useState(false)
    
    // useEffect(() => window.scrollTo(0, 0), [modal]); //scroll to top of page


    // Loop Engagement section
    useEffect(() => {
      const loopEngagment = setTimeout(() => {setEngagement(engagement === 0 ? 1 : 0)}, 5000);
      return () => clearTimeout(loopEngagment)
    },)

    //Loop Big video background
    useEffect(() => {
        const backgroundVid = setTimeout(() => {setIndustry(industry === 2 ? 0 : industry + 1)}, 5000);
        return () => clearTimeout(backgroundVid)
      },)

      //Loop industry
    useEffect(() => {
        const changeIndustry = setTimeout(() => {setbgVideo(bgVideo === 2 ? 0 : bgVideo + 1)}, 5000);
        return () => clearTimeout(changeIndustry)
      },)

    

    return (
        <>
            <Waitlist modal={modal} closeModal={closeModal} />
            {/* Set Page title */}
            <PageTitle title="Home | Clientshot Website" /> 
            
            <div className='mx-auto section-top'>
                {/* section one */}
                <div className={bgVideo === 0 ? "md:flex justify-between mt-16 md:mt-0 md:gap-x-4 healthcare-video" : bgVideo === 1 ? "md:flex justify-between mt-16 md:mt-0 md:gap-x-4 aviation-video" : "md:flex justify-between mt-16 md:mt-0 md:gap-x-4 banking-video"} style={{position : 'relative'}}>
                    <div className='flex flex-col justify-center items-center col-1 p-4 md:p-8 mt-8 md:mt-24 w-full'>

                        <div className='text-white hero-section' style={{maxWidth : '80%'}}>
                            <div className='heading-two text-center hero-heading' id='hero-heading'>
                                Increase customer retention, boost sales, enhance reputation, and drive innovation
                            </div>
                            <div className="heading-six secondary-100 text-center mt-6 md:mt-6 desc" style={{ fontWeight: 400 }}>
                            Harness the power of data-driven insights to propel your business forward.
                            </div>
                            <div className='flex justify-center gap-x-3 md:mt-4'>

                                

                                <Link
                                    
                                    to={`/pricing`}
                                    className='btn btn-primary btn-lg mt-6 flex gap-2 w-max'>
                                    Signup Now
                                    
                                </Link>
                            </div>
                            <div className=' flex flex-col justify-center stars items-center mt-12 md:mt-12 mb-8 md:mb-0'>
                                <div className='flex gap-2'>
                                    
                                    <img src={Star} alt="star" />
                                    <img src={Star} alt="star" />
                                    <img src={Star} alt="star" />
                                    <img src={Star} alt="star" />
                                    <img src={Star} alt="star" />
                                </div>
                                <p className="base-text-m mt-2.5 md:mt-4">
                                    The Ultimate Customer Feedback Solution for
                                </p>
                                <div className="flex heading-four">
                                    <p className="primary-500 mr-1 ">
                                        {industry === 0 ? 'HealthCare' : industry === 1 ? 'Aviation' : 'Banking'}
                                    </p>
                                    Industry
                                </div>
                            </div>

                            
                        </div>
                    </div>

                    <div className='w-max scroll-line' style={{cursor : 'pointer'}} onClick={() => window.scrollTo(0, screenY- 50)}>
            
                    </div>
                    {/* {transitionVideos()} */}
                </div>

                {/* section two */}

                <div className='pt-16 md:pt-16 p-8 section-one' style={{overflow : 'hidden'}}>

                    <div className='mb-14 md:mb-2'>
                        <div data-aos="fade-up" className="heading-six font-bold satisfaction secondary-200 " >
                        Unaddressed complaints harm sales and reputation
                        </div>
                        <div data-aos="fade-up" className="heading-four md:w-2/4">
                           <span className='primary-500'>Discover</span>  how Clientshot can improve your Customer Experience
                        </div>
                    </div>

                </div>

                <div className='flex justify-center screens' style={{overflow : 'hidden'}}>
                        <img src={Mockup01} loading="lazy" alt="mockup" style={{ height : '100%' }} />
                        <img src={Mockup01} loading="lazy" alt="mockup" style={{ height : '100%' }} /> 
                         <img src={Mockup01} loading="lazy" alt="mockup" style={{ height : '100%' }} />
                    </div>

                <div className="hrline w-full mb-8 mt-16"></div>

                {/* section three */}

                <div className=' md:pr-8 p-4 md:p-8 section-three'>

                    <div className='flex flex-col md:flex-row justify-between md:items-center gap-12'>

                        <div data-aos="zoom-in-up" className='order-last'>
                            <img src={Mockup04} loading="lazy" className='mockup02' alt="mockup" style={{ maxWidth: 652 }} />
                        </div>

                        <div className='md:w-9/12'>
                            <div data-aos="fade-up" className="pill secondary-400 small-text mb-4">
                                <img src={LightBulb} alt="mockup" />
                                Our Solution
                            </div>
                            
                            <div data-aos="fade-up" className="heading-four mb-6 secondary-400"> Empowering Business Success through <span className='primary-500'>Customer Feedback</span></div>
                            <div data-aos="fade-up" className="base-text secondary-400" style={{ fontWeight: 400 }}>
                            Clientshot is a cutting-edge feedback system designed to assist businesses in gathering valuable insights, including identifying pain points and receiving suggestions from customers. <br /> <br /> The application also provides tools to effectively address and resolve customer complaints, ensuring continuous improvement and satisfaction for customers.
                            </div>
                        </div>

                    </div>

                </div>



                {/* section four */}

                <div className=" p-4 md:p-8 pt-40 ">
                    <div className="w-full section-four">
                        <div>
                            <img src={Lines} alt="lines" className='w-full' />
                            <div className='flex flex-col p-4 items-center w-full' style={{marginTop : -18}}>
                                <div data-aos="fade-up" className="pill secondary-400 small-text mb-4">
                                    Collect Feedback
                                </div>
                            
                                <div data-aos="fade-up" className="heading-four text-center mb-2 secondary-400">How customer feedbacks are <span className='primary-500'>captured</span></div>
                                <div data-aos="fade-up" className="base-text  text-center secondary-400" style={{ fontWeight: 400 }}>
                                    Various channels are available for businesses to efficiently capture customer feedback for resolution.
                                </div>
                            </div>
                        </div>

                        <div>
                        <div data-aos="fade-up" className=" items-center mt-8 flex justify-center">
                    <div className="flex flex-col items-center w-full">
                        <div className="channel-switcher flex flex-wrap gap-x-2">
                            <button
                                className={channel === 0 && 'active'}
                                onClick={() => setChannel(0)}
                            >
                                Web App
                            </button>

                            <button
                                className={channel === 1 && 'active'}
                                onClick={() => setChannel(1)}
                            >
                                WhatsApp
                            </button>

                            <button
                                className={channel === 2 && 'active'}
                                onClick={() => setChannel(2)}
                            >
                                USSD <span className='badge discount-tag ml-2'>Coming soon</span>
                            </button>
                        </div>

                        {channel === 0 && <div className="content p-4 md:p-8 mt-16 w-full">
                            <div className="flex md:flex-row flex-col gap-4">
                                <div data-aos="fade-up" className="feature-card">
                                    <div className="image" >
                                        <video src={QrcodeVideo} autoPlay={true} loop muted></video>
                                    </div>
                                    <div className="content w-full p-4">
                                        <h5 className="base-text font-bold mb-2 secondary-400">Get feedback with a unique <span className='primary-500'>QR code</span></h5>
                                        <p className='secondary-300 base-text'>Receive feedback from your customers when they scan your unique QR code</p>
                                    </div>
                                </div>
                                
                                <div data-aos="fade-up" data-aos-delay="100" className="feature-card">
                                    <div className="image" >
                                        <img src={UniqueLink} style={{marginTop : "-70%"}} alt='unique link'/>
                                    </div>
                                    <div className="content p-4">
                                        <h5 className="base-text font-bold mb-2 secondary-400">Receive feedback using a <span className='primary-500'>unique link</span></h5>
                                        <p className='secondary-300 base-text'>Sharing a unique link with your audience allows you to easily receive useful feedback</p>
                                    </div>
                                </div>


                                <div data-aos="fade-up" data-aos-delay="200" className="feature-card">
                                    <div className="image justify-center" >
                                        <div className='url-shortener'>
                                            <div className="link-input secondary-300">
                                                <div className={shortenUrl ? 'dittolink shorten' : 'dittolink'}>
                                                    https://clientshot.com/dittohub
                                                    <br /> <br />
                                                    https://clientshot.com/dhub
                                                </div>
                                                
                                            </div>
                                            <button className='btn btn-primary' style={{fontSize : 12.8, padding : '8px 14px', borderRadius : 8}} >Copy link</button>
                                        </div>
                                        <div className='mt-3 items-center flex' style={{cursor : 'pointer'}}>
                                            <input type="checkbox" name="" aria-label="check" id="" style={{cursor : 'pointer'}} onClick={() => setshortenUrl(!shortenUrl)}  checked={shortenUrl} />
                                            <span className="ml-2 secondary-300 small-text" style={{fontWeight : '500'}} onClick={() => setshortenUrl(!shortenUrl)}>Click to shorten url</span>
                                        </div>
                                    </div>
                                    <div className="content p-4">
                                        <h5 className="base-text font-semibold mb-2 secondary-400">Use a <span className='primary-500'>shortened URL</span></h5>
                                        <p className='secondary-300 base-text'>Make your unique url more memorable with a shortened URL</p>
                                    </div>
                                </div>
                            </div>
                        </div>}

                        {channel === 1 && 
                            <div className="channel-tab whatsapp justify-center p-4 md:p-8 mt-16">

                            <div className="flex justify-center flex-col md:flex-row">
                                <div className="content order-first md:order-last w-full">
                                
                                        <div className='mb-4'> 
                                            <div className="heading-six font-bold mb-2 secondary-400">Leverage WhatsApp integration</div>
                                            <p className='secondary-300 small-text'>Collect feedback from users who prefer using WhatsApp</p>
                                        </div>

                                        <div className="feature-card items-center flex flex-col" style={{height : 300}}>
                                                <div className='url-shortener mt-16'>
                                                    <div className="link-input secondary-300">
                                                        <div className={shortenUrl ? 'dittolink shorten' : 'dittolink'}>
                                                            https://wa.me/+234902300002
                                                            <br /> <br />
                                                            https://clientshot.com/dhub
                                                        </div>
                                                
                                                    </div>
                                                    <button className='btn btn-primary' style={{fontSize : 12.8, padding : '8px 14px', borderRadius : 8}} >Copy link</button>
                                                </div>

                                            <div className="content p-4">
                                                <h5 className="base-text font-bold mb-2 secondary-400">Get feedback with a <span className='primary-500'>WhatsApp link</span></h5>
                                                <p className='secondary-300 small-text'>Receive feedback from your customers when they scan your unique QR code</p>
                                            </div>
                                        </div>
                                        
                                </div>
                                
                                <div data-aos="zoom-in-up" className="w-full  flex justify-center">
                                        <video src={whatsappMockup} loop autoPlay={true} alt="" style={{width : 300}} />
                                </div>                                
                            </div>
                        </div>
                        }

                        {channel === 2 && 
                            <div className="channel-tab justify-center p-4 md:p-8 mt-16">

                            <div className="flex justify-center flex-col md:flex-row ">
                                <div className="content md:mt-40 md:w-2/5">
                                    <div className='ussd-content'>
                                        <div data-aos="fade-up" className="heading-six font-bold mb-2 secondary-400 ">Offline feedback for Tech-Novices</div>
                                        <p data-aos="fade-up" className='secondary-300 small-text'>Facilitate feedback collection from customers less familiar with technology, especially useful when internet access is unavailable.</p>
                                    </div>
                                    
                                </div>
                                
                                <div data-aos="zoom-in-up" className="mockup">
                                        <img src={ussdMockup} loading="lazy" alt="usd mockup" style={{width : 300}} />
                                </div>

                                
                            </div>
                        </div>
                        }

                    </div>
                </div>
                        </div>
                    </div>
                </div>


                <div className='pt-40 '>

                    <div className='md:flex justify-between gap-4 items-start p-8'>
                        <div className='resolute-texts md:sticky top-56'>
                            <div data-aos="fade-up" className="pill secondary-400 small-text mb-4">
                                    <img src={ResolutionIcon} alt="resolution icon" />
                                    Resolution
                                </div>
                                
                                <div data-aos="fade-up" className="heading-four mb-6 secondary-400"> Manage and <span className='primary-500'>resolve</span> customer complaints</div>
                                <div data-aos="fade-up" className="base-text secondary-400" style={{ fontWeight: 400 }}>
                                    A simplified solution that allows you to efficiently monitor, track, and resolve customer issues as they arise.
                                </div>
                        </div>

                        <div data-aos="zoom-in-up" className='md:w-9/12'>
                            <div className="resolution-feature mb-8">
                                <div className="content p-4">
                                    <div className="heading-six font-bold mb-2 secondary-400"><span className='primary-500'>Hands-on</span> Ticketing</div>
                                    <p className='secondary-300 small-text'>Manually create complaint tickets for customers who voice their concerns in person, ensuring their issues are promptly addressed and resolved.</p>
                                    <div className="image">
                                        <img src={HandsOnMockup} loading="lazy" alt="handson mockup" />
                                    </div>
                                </div>
                            </div>

                            <div className="resolution-feature mb-8">
                                <div className="content p-4">
                                    <div className="heading-six font-bold mb-2 secondary-400"><span className='primary-500'>Assign</span>  complaints to teammates</div>
                                    <p className='secondary-300 small-text'>Delegate customer complaints to team members, boost productivity and resolve issues faster.</p>
                                    <div className="image flex justify-center">
                                        <video data-aos="fade" style={{width : 400}} src={UnassignVid} autoPlay={true} loop muted></video>
                                    </div>
                                </div>
                            </div>

                            <div className="resolution-feature">
                                <div className="content p-4">
                                    <div className="heading-six font-bold mb-2 secondary-400">Informative Status <span className='primary-500'>Updates</span></div>
                                    <p className='secondary-300 small-text'>Move tickets from 'In Progress' to 'Done,' keeping your customers in the loop throughout the complaint resolution process.</p>
                                    <div className="image flex justify-center">
                                        <video data-aos="fade" style={{width : 400}} src={UpdateStatus} autoPlay={true} loop muted></video>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="hrline-alt w-full mb-8 mt-16"></div>

                {/* Engagement section */}

                <div className='mt-24 md:mt-0 md:pr-8 engagement-section'>
                    <div className='flex flex-col items-center justify-center'>
                        <div data-aos="fade-up" className="pill secondary-400 small-text mb-4">
                            <img src={EngagementIcon} alt="" />
                            Engagement
                        </div>
                        
                        <div data-aos="fade-up" className="heading-four mb-6 text-center secondary-400"> <span className='primary-500'>Engage</span> your customers when they respond </div>
                        
                        <div className='flex w-full flex-col md:flex-row justify-between md:items-center p-8 gap-12'>

                            <div className="engagement-tab">
                                <button className={engagement === 0 ? 'active' : ''} onClick={() => setEngagement(0)}>
                                    Chat
                                    {engagement === 0 ? <img src={ArrowRight} alt="" /> : <></>}
                                    </button>
                                <button className={engagement === 1 ? 'active' : ''}  onClick={() => setEngagement(1)}>
                                    Text message
                                    {engagement === 1 ? <img src={ArrowRight} alt="" /> : <></>}
                                </button>
                            </div>

                            {engagement === 0 && <div className='flex items-center gap-12 text-message flex-col md:flex-row '>
                                <div data-aos="zoom-in-up" className='order-last md:order-first'>
                                    <img src={ChatScreen} loading="lazy" className='mockup02' alt="" style={{ maxWidth: 552 }} />
                                </div>
                                <div className='md:w-9/12'>
                                
                                    <div data-aos="fade-up" className="heading-five mb-2 md:mb-6 secondary-400"> Team-Enabled Customer <span className='primary-500'>Chat</span></div>
                                    <div data-aos="fade-up" className="base-text secondary-400" style={{ fontWeight: 400 }}>
                                    Initiate a secure chat with customers online to identify and promptly resolve their issues. And yes, team members can join the conversation if necessary.
                                    </div>
                                </div>
                            </div>}

                            {engagement === 1 && <div className='flex items-center gap-12 text-message flex-col md:flex-row '>
                                <div data-aos="zoom-in-up" className='order-last md:order-first'>
                                    <img src={TextScreen} loading="lazy" className='mockup02' alt="" style={{ maxWidth: 552 }} />
                                </div>
                                <div className='md:w-9/12'>
                                
                                    <div data-aos="fade-up" className="heading-five mb-2 md:mb-6 secondary-400"> Resolution Updates and <span className='primary-500'>SMS Marketing</span></div>
                                    <div data-aos="fade-up" className="base-text secondary-400" style={{ fontWeight: 400 }}>
                                    Keep customers updated on issue resolutions. This communication method is also effective for SMS marketing.
                                    </div>
                                </div>
                            </div>}

                        </div>
                    </div>
                </div>


                <div data-aos="fade" className='mt-40 md:pr-8 p-4 md:p-8' >
                    <div className='flex flex-col md:flex-row justify-between md:items-center p-4 md:p-8 gap-12 rounded-lg analysis-section' >
                    <div data-aos="zoom-in-up" className='order-last'>
                        <img src={DashboardMockup} loading="lazy" className='mockup02 analyse-dashboard' alt="" style={{ marginTop : '10rem', maxWidth: '100%' }} />
                    </div>

                    <div className='md:w-9/12'>
                        <div data-aos="fade-up" className="pill secondary-400 small-text mb-4">
                            <img src={AnalyseIcon} alt="" />
                            Analysis
                        </div>
                        <div data-aos="fade-up" className="heading-four mb-6 secondary-400"><span className='primary-500'>Analyze</span> the data you collect </div>
                        <div data-aos="fade-up" className="base-text secondary-400" style={{ fontWeight: 400 }}>
                        Our advanced reporting tool will help you analyze your feedback data so you can better understand it and respond. It also tells you which service points are performing well, which channels your customers prefer to use to respond to a survey, and more.
                        </div>
                    </div>

                    </div>
                </div>

                <div className="hrline w-full mb-8 mt-16"></div>

                {/* testimonials and customers */}

                <div data-aos="fade" className='mt-24 md:mt-0 flex flex-col items-center md:pr-8 p-4 md:p-8 clients' style={{overflow : 'hidden'}}>
                    <div data-aos="fade-up" className="heading-four text-center mb-12 secondary-400"> Providing data insights for the top leading companies </div>
                    <div data-aos="fade" className='flex gap-8 md:gap-24 flex-wrap justify-center'>
                        <img src={Ihvn} className='client' alt="" />
                        <img src={Valuejet} className='client' alt="" />
                        <img src={Firmcare} className='client' alt="" />
                        <img src={Ditto} className='client' alt="" />
                    </div>
                </div>

                <div className="hrline-alt w-full mb-8 mt-16"></div>

                <div className=' mt-24 md:mt-0 md:pr-8 story-section'>
                    <div className='flex flex-col items-center justify-center'>
                        <div data-aos="fade-up" className="pill secondary-400 small-text mb-4">
                            <img src={TrophyIcon} alt="" />
                            Client success stories
                        </div>
                        
                        <div data-aos="fade-up" className="heading-four text-center mb-6 secondary-400">See how businesses like yours have benefited</div>
                        
                        <div className='grid grid-cols-1 md:grid-cols-3 gap-6 p-4 md:px-32'>
                            <div  data-aos="fade" >
                                <div className="testimony">
                                    <div className="flex justify-start items-center gap-2">
                                    <img src={pfp2} className='pfp' alt="" />
                                        <div className="info">
                                            <p className="secondary-400 font-medium base-text">Akande Omobolanle</p>
                                            <p className="secondary-200 small-text">Marketing Manager, Bright Ideas Inc.</p>
                                        </div>
                                    </div>
                                    <div className="comment secondary-300 small-text">
                                        With this tool, collecting feedback from our customers has never been easier! Highly recommended.
                                    </div>
                                </div>
                            </div>

                            <div data-aos="fade" data-aos-delay="100">
                                <div  className="testimony">
                                    <div className="flex justify-start items-center gap-2">
                                    <img src={pfp1} className='pfp' alt="" />
                                        <div className="info">
                                            <p className="secondary-400 font-medium base-text">Eze Chinedu</p>
                                            <p className="secondary-200 small-text">Customer Support Specialist, Service Excellence Inc.</p>
                                        </div>
                                    </div>
                                    <div className="comment secondary-300 small-text">
                                    We've received such positive feedback from our customers since implementing this tool. It's user-friendly and effective!
                                    </div>
                                </div>
                            </div>

                            <div data-aos="fade" data-aos-delay="200">
                                <div className="testimony">
                                    <div className="flex justify-start items-center gap-2">
                                        <img src={pfp3} className='pfp' alt="" />
                                        <div className="info">
                                            <p className="secondary-400 font-medium base-text">Tofunmi Akinlade</p>
                                            <p className="secondary-200 small-text">Operations Coordinator, Peak Performance Solutions</p>
                                        </div>
                                    </div>
                                    <div className="comment secondary-300 small-text">
                                    A game-changer for us! The unique links and QR codes make feedback collection a breeze
                                    </div>
                                </div>
                            </div>

                            <div data-aos="fade" >
                                <div className="testimony">
                                    <div className="flex justify-start items-center gap-2">
                                    <img src={pfp4} className='pfp' alt="" />
                                        <div className="info">
                                            <p className="secondary-400 font-medium base-text">Oladejo israel</p>
                                            <p className="secondary-200 small-text">Sales Representative, Innovate Technologies.</p>
                                        </div>
                                    </div>
                                    <div className="comment secondary-300 small-text">
                                    Our customers love the simplicity of providing feedback using this tool. It's a win-win for everyone!
                                    </div>
                                </div>
                            </div>

                            <div data-aos="fade" data-aos-delay="100" >
                                <div className="testimony">
                                    <div className="flex justify-start items-center gap-2">
                                    <img src={pfp5} className='pfp' alt="" />
                                        <div className="info">
                                            <p className="secondary-400 font-medium base-text">Adegboyoga Precious</p>
                                            <p className="secondary-200 small-text">IT Manager, Digital Dynamics</p>
                                        </div>
                                    </div>
                                    <div className="comment secondary-300 small-text">
                                    An essential tool for any business looking to gather valuable insights from their customers. Fantastic!
                                    </div>
                                </div>
                            </div>

                            <div data-aos="fade" data-aos-delay="200">
                                <div className="testimony">
                                    <div className="flex justify-start items-center gap-2">
                                        <img src={pfp6} className='pfp' alt="" />
                                        <div className="info">
                                            <p className="secondary-400 font-medium base-text">Aminat Taiwo </p>
                                            <p className="secondary-200 small-text">Project Manager, Stellar Solutions Ltd.
                                </p>
                                        </div>
                                    </div>
                                    <div className="comment secondary-300 small-text">
                                    The customizable options for feedback collection make this tool stand out. It's been a great asset to our company.
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                {/* video section */}

                

                {/* popup video */}

                {
                    popup && (
                        <div data-aos="zoom-up" className="popup h-full w-full">
                            {/* <button onClick={() => setPopup(false)}><CloseButton /></button> */}
                            <div className="main-video flex flex-col gap-y-4 items-center">
                                <iframe width="800" height="600" style={{ borderRadius: 15 }} src="https://www.youtube.com/embed/_QmyvFOasGk?si=2lLN11xULFRPBSNK&autoplay=1" allow='autoplay; encrypted-media' allowfullscreen title="Client Shot Video"></iframe>
                                <button onClick={() => setPopup(false)} className='flex gap-2'>
                                    <img src={Close} alt="" /> Close
                                </button>
                            </div>

                        </div>
                    )
                }


                {/* section five */}


                


                <div className='mt-40 px-4 md:p-8 jumbotron-cover'>
                    <div className='bg-black px-4 md:p-20 md:px-32 py-20 jumbotron' style={{ borderRadius: 30 }}>
                        <div data-aos="fade-up" className="heading-three text-center md:text-left text-white">
                            Elevate your business, boost your reputation, and increase your profits by enhancing your customer experience.
                        </div>
                        <div className='flex justify-center gap-4 md:justify-start'>
                            <Link
                                to={`/pricing`}
                                className='btn btn-primary btn-lg mt-6 flex items-center gap-2 w-max'>
                                Get Started
                                {/* <img src={ChevronRight} alt="" /> */}
                                <FontAwesomeIcon icon={faChevronRight} style={{fontSize : 14}} />
                            </Link>

                            <Link
                                    onClick={() => setPopup(true)}
                                    className='btn btn-alt btn-lg mt-6 flex gap-2 w-max'>
                                    <FontAwesomeIcon icon={faPlayCircle} size={24} />
                                    Watch demo
                                    
                                </Link>
                        </div>

                    </div>
                </div>

                {/* section six */}

                <div className='pt-40 mb-20 px-4 md:px-8 section-six'>
                    <div data-aos="fade-up" className="heading-four text-left md:text-center">Frequently Asked Questions</div>

                    <div className="md:flex gap-8 mt-12 md:mt-20">
                        <div data-aos="fade-up" className='md:w-2/4'>
                            <Accordion title="What is Clientshot?" desc="Clientshot is a customer satisfaction tool that helps businesses collect and analyze feedback from their customers." />
                            <Accordion title="How does Clientshot measure customer satisfaction?" desc="Clientshot measures customer satisfaction by collecting feedback from customers through various channels, such as USSD, Web, and WhatsApp. The feedback is analyzed to calculate metrics such as Net Promoter Score and Customer Satisfaction." />
                            <Accordion title="How does Clientshot collect customer feedback?" desc="Clientshot collects customer feedback through USSD, Web, and WhatsApp. The feedback can be collected through surveys, ratings, and other forms of interaction." />
                            <Accordion title="How does Clientshot help businesses improve their customer satisfaction?" desc="Clientshot helps businesses improve their customer satisfaction by providing insights into how customers feel about their experience with the business. The feedback and analytics provided by Clientshot help businesses identify areas for improvement and make changes that will result in better customer satisfaction." />
                        </div>

                        <div data-aos="fade-up" className='md:w-2/4'>
                            <Accordion title="What metrics does Clientshot track?" desc="Clientshot tracks metrics such as Net Promoter Score and Customer Satisfaction. The built-in analytics also provide insights into the impact of customer feedback on these metrics over time." />
                            <Accordion title="How is customer data stored and protected by Clientshot?" desc="Customer data is stored securely by Clientshot and is protected by industry-standard security measures." />
                            <Accordion title="How can I get started with Clientshot?" desc="To get started with Clientshot, you can click this link or contact us for more information." />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home