import React, { useState, useLayoutEffect } from 'react'
import PageTitle from '../components/title/PageTitle'
import UserIcon from '../assets/svgs/user.svg'
import MailIcon from '../assets/svgs/mail.svg'
import IndustryIcon from '../assets/svgs/industry.svg'
import PhoneIcon from '../assets/svgs/phone-icon.svg'
import EmailIcon from '../assets/svgs/email-icon.svg'
import ContactFeedback from '../components/modals/ContactFeedback'
import axios from 'axios'

const Contact = () => {
 
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [message, setMessage] = useState("")
    const [company, setCompany] = useState("")
    const [loader, setLoader] = useState(false)
    const [success, setSuccess] = useState(false)
    const [modal, setModal] = useState(false)

    // error states
    const [nameError, setnameError] = useState()
    const [emailError, setemailError] = useState()
    const [phoneError, setphoneError] = useState()
    const [messageError, setmessageError] = useState()
    const [companyError, setcompanyError] = useState()
    const [mainError, setmainError] = useState(false)

    const closeModal = () => setModal(!modal) //close modal

    useLayoutEffect(() => window.scrollTo(0, 0)); //scroll to top of page

    const clearFields = () =>{
        setName("")
        setPhone("")
        setCompany("")
        setMessage("")
        setEmail("")
    }

    const submitForm = async (e) => {
        e.preventDefault()

        setModal(true)
        setLoader(true)

        try {
            let resp = await axios.post(process.env.REACT_APP_CONTACT, {
                'fullName' : name,
                'email' : email,
                'companyName' : company,
                'phoneNumber' : phone,
                'messageBody' : message
            })    
            
            setLoader(false)
        
            setSuccess(true)
            clearFields()
        
        } catch (error) {
            setLoader(false)
            setModal(false)
           

            let [response] = error.response.data.errors

     
            if(response.field === "fullName"){
                setnameError(response)
            }if(response.field === "email"){
                setemailError(response)
            }if(response.field === "phoneNumber"){
                setphoneError(response)
            }if(response.field === "companyName"){
                setcompanyError(response)
            }if(response.field === "messageBody"){
                setmessageError(response)
            }

            if(error.response.data.statusCode === 500){
                setmainError(true)
                setTimeout(()=>{
                    setmainError(false)
                }, 5000)
            }
            clearFields()
        }

    }

    
    
  return (
    <>

        <ContactFeedback loader={loader} success={success} modal={modal} closeModal={closeModal} />
        {/* Set Page title */}
        <PageTitle title="Contact us | Clientshot Website " /> 
        <div className=' mt-24'>
            <section className="container mx-auto px-4 md:px-32">
                <div className=''>
                    <h6  data-aos="fade-up" className="heading-six primary-500">Contact Us</h6>
                    <h3  data-aos="fade-up" className="heading-three">We love to hear from you, <br /> Get in touch 👋</h3>
                    <p  data-aos="fade-up" className="base-text mt-6 md:mt-0">If you need assistance or have any questions, please fill out the form below and we will get back to you as soon as possible.</p>
                </div>

                {/* connection error */}
                {mainError ? (
                    <div className='p-3 my-2 rounded-lg' style={{backgroundColor : '#FFD6D6'}}>
                        <p className='base-text' style={{color : 'red'}}>Please Check your internet connection and try again</p>
                    </div>
                ): <></>}

                <div className=' mt-14'>
                    <form onSubmit={submitForm} method='POST'>
                        <div className="md:flex gap-x-4">
                            <div data-aos="fade-up" data-aos-delay="100" className='w-full'>
                                <span className='input-icon'><img src={UserIcon} alt="" /></span>
                                <input type="text" className={nameError != null ? "form-input w-full prepend input-error" : "form-input w-full prepend"} onChange={(e) => setName(e.target.value)} placeholder='Name' value={name} required />
                                {nameError != null ? <p className='text-red small-text'>{nameError.message}</p>: <></>}
                            </div>
                            
                            <div data-aos="fade-up" data-aos-delay="200"  className='w-full mt-4 md:mt-0'>
                                <span className='input-icon'><img src={MailIcon} alt="" /></span>
                                <input type="email" className={emailError != null ? "form-input w-full prepend input-error" : "form-input w-full prepend"}  onChange={(e) => setEmail(e.target.value)} placeholder='Email Address' value={email} required />
                                {emailError != null ? <p className='text-red small-text'>{emailError.message}</p>: <></>}
                            </div>
                            
                        </div>

                        <div className="md:flex mt-4 md:mt-6 gap-x-4">
                            <div data-aos="fade-up" data-aos-delay="300"  className="w-full">
                                <input type="number" className={phoneError != null ? "form-input w-full input-error" : "form-input w-full "}  onChange={(e) => setPhone(e.target.value)} placeholder='Phone Number' value={phone} required />
                                {phoneError != null ? <p className='text-red small-text'>{phoneError.message}</p>: <></>}
                            </div>
                            <div data-aos="fade-up" data-aos-delay="400"  className="w-full mt-4 md:mt-0">
                                <span className="input-icon"><img src={IndustryIcon} alt="" /></span>
                                <input type="text" className={companyError != null ? "form-input w-full prepend input-error" : "form-input w-full prepend"}  onChange={(e) => setCompany(e.target.value)} placeholder='Name of Company' value={company} required />
                                {companyError != null ? <p className='text-red small-text'>{companyError.message}</p>: <></>}
                            </div>
                        </div>

                        <div data-aos="fade-up" data-aos-delay="500"  className="flex mt-6 gap-x-4">
                            <textarea name="" className={messageError != null ? "form-input w-full input-error" : "form-input w-full "}  id="" cols="30" rows="10" onChange={(e) => setMessage(e.target.value)} placeholder='Write your message' value={message} ></textarea>
                            {messageError != null ? <p className='text-red small-text'>{messageError.message}</p>: <></>}
                        </div>

                        <div data-aos="fade-up" data-aos-delay="100"  className="mt-6">
                            <button className="w-full btn btn-primary btn-lg">
                                Send Message
                            </button>
                        </div>
                    </form>
                </div>
            </section>

            <section className='py-24 section-one'>
                <div className="container mx-auto px-6 md:px-32 ">
                    <h6 data-aos="fade-up" className="heading-six">Alternatively, you can reach us at the following email and <br /> phone number:</h6>

                    <div className="mt-10">
                        <div data-aos="fade-up" data-aos-delay="100"  className="flex gap-x-3 items-center">
                            <img src={PhoneIcon} alt="" />
                            <div>
                                <p className="base-text secondary-200">Phone</p>
                                <p className="base-text font-bold">+234 (0) 915 110 4924</p>
                            </div>
                        </div>
                    </div>

                    <div className="mt-10">
                        <div data-aos="fade-up" data-aos-delay="200"  className="flex gap-x-3 items-center">
                            <img src={EmailIcon} alt="" />
                            <div>
                                <p className="base-text secondary-200">Email</p>
                                <p className="base-text font-bold">info@clientshot.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </>
  )
}

export default Contact