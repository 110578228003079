import React, { useState, useContext } from 'react'
import PageTitle from '../components/title/PageTitle'
import { LocationContext } from '../components/contexts/LocationContext'
import GrowthPricingTable from '../components/GrowthPricingTable'
import ProPricingTable from '../components/ProPricingTable'
import StarterSubPricingTable from '../components/subscription/StarterSubPricingTable'
import GrowthSubPricingTable from '../components/subscription/GrowthSubPricingTable'
import ProSubPricingTable from '../components/subscription/ProSubPricingTable'
import CheckCircle from '../assets/svgs/check-circle-white.svg'
import CheckClose from '../assets/svgs/close-circle.svg'
import BoxIllustration from '../assets/images/boxes+illustration.png'
import ChevronRight from '../assets/svgs/chevron-right.svg';
import StarterPricingTable from '../components/StarterPricingTable';
import EnterprisePricingTable from '../components/EnterprisePricingTable';
import Accordion from '../components/Accordion';


const Pricing = () => {
    React.useLayoutEffect(() => window.scrollTo(0, 0)); //scroll to top of page
    const [duration, setDuration] = useState(0); //switch  between monthly and annually
    const model = 0; 
    const symbol = ({ ngn: 'N', usd: '$' }) // Switch between N or $
    const dollar = 1000; //dollar price

    const { currency, setCurrency } = useContext(LocationContext) //currency context from Layout.jsx

    return (
        <>
            {/* Set Page title */}
        <PageTitle title="Pricing | Clientshot Website" /> 
            <div className=" mx-auto mt-24 md:mt-24 px-4 md:px-8 items-center">
                <div className='w-full'>
                    <h6 data-aos="fade-up" className="heading-six primary-500 text-center desc">Plans and Pricing</h6>
                    <h3 data-aos="fade-up" className="heading-three text-center ">Clientshot's value packed plans</h3>
                    <p data-aos="fade-up" className="base-text text-center secondary-300">
                        Unlocking unbeatable value for your business and catering to your needs with suitable plans
                    </p>
                    <div data-aos="fade-up" className="flex justify-center mt-3">
                        <a href='https://app.clientshot.com/' target={'_blank'} rel={'noreferrer'} className='btn btn-primary flex btn-lg gap-2 w-max'>
                            Start free trial
                            <img src={ChevronRight} alt="" />
                        </a>
                    </div>
                </div>
            </div>


            {model === 0 &&
                <div data-aos="fade-up" className="container mx-auto w-full items-center mt-8 flex justify-center">
                    <div className="">
                        <div className="price-switcher flex flex-wrap gap-x-2">
                            <button
                                className={duration === 0 && 'active'}
                                onClick={() => setDuration(0)}
                            >
                                Monthly
                            </button>

                            <button
                                className={duration === 1 && 'active'}
                                onClick={() => setDuration(1)}
                            >
                                Annually <span className='badge discount-tag ml-2'>Save up to 40%</span>
                            </button>
                        </div>
                    </div>
                </div>
            }



            <div className=" mx-auto mt-14 px-4 md:px-8 items-center">

                {
                    model === 1 && (
                        <div className=" md:flex justify-center gap-8 ">
                            <StarterPricingTable />
                            <GrowthPricingTable />
                            <ProPricingTable />
                        </div>
                    )
                }

                {
                    model === 0 && (
                        <div className='md:flex justify-center flex-wrap gap-8'>
                            <StarterSubPricingTable duration={duration} currency={currency} symbol={currency === 0 ? symbol.ngn : symbol.usd} dollar={dollar} />
                            <GrowthSubPricingTable duration={duration} currency={currency} symbol={currency === 0 ? symbol.ngn : symbol.usd} dollar={dollar} />
                            <ProSubPricingTable duration={duration} currency={currency} symbol={currency === 0 ? symbol.ngn : symbol.usd} dollar={dollar} />
                        </div>
                    )
                }


                <div className='flex justify-center mt-12'>
                    <EnterprisePricingTable />
                </div>
            </div>


            <div className="container mx-auto mt-40 md:mt-36 px-4 md:px-8 items-center">

                <div data-aos="fade-up" data-aos-easing="ease-out" className="md:flex hidden justify-center">
                    <div className="gap-8 plan-comparism p-6">
                        <img src={BoxIllustration} className='illustration' alt="" />
                        <h3 className="heading-three text-white mb-6">
                            Plan Comparison
                        </h3>

                        <table className='w-full text-white'>
                            <thead>
                                <th className='desc-col'></th>
                                <th style={{ width: '17%' }}>
                                    Starter
                                </th>
                                <th style={{ width: '15%' }}>
                                    Growth
                                </th>
                                <th style={{ width: '20%' }}>
                                    Pro
                                </th>
                            </thead>

                            <tbody>
                                <tr className='mb-4 hidden'>
                                    <td className='desc-col' style={{ color: 'white', textAlign: 'left' }}>Setup fee</td>
                                    <td>0.00 NGN</td>
                                    <td>350,000.00 NGN</td>
                                    <td>750,000.00 NGN</td>
                                </tr>
                            </tbody>

                        </table>

                        <div className="compare-table w-full">
                            <table className='w-full text-white'>
                                <thead className='hidden'>
                                    <th className='desc-col'></th>
                                    <th>
                                        Starter
                                    </th>
                                    <th>
                                        Growth
                                    </th>
                                    <th>
                                        Pro
                                    </th>
                                </thead>
                                <tbody>
                                    <tr className='mb-4'>
                                        <td className='desc-col' style={{ color: 'white', textAlign: 'left' }}>Price</td>
                                        <td style={{ width: '19%' }}>6,500 NGN</td>
                                        <td style={{ width: '14%' }}>15,000 NGN</td>
                                        <td>25,200 NGN</td>
                                    </tr>
                                    <tr>
                                        <td style={{ color: 'white', textAlign: 'left' }}>Reporting and Analytics</td>
                                        <td>
                                            <div className="flex justify-center"><img src={CheckCircle} alt="" /></div>
                                        </td>
                                        <td>
                                            <div className="flex justify-center"><img src={CheckCircle} alt="" /></div>
                                        </td>
                                        <td>
                                            <div className="flex justify-center"><img src={CheckCircle} alt="" /></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ color: 'white', textAlign: 'left' }}>Activity Logs</td>
                                        <td>
                                            <div className="flex justify-center"><img src={CheckCircle} alt="" /></div>
                                        </td>
                                        <td>
                                            <div className="flex justify-center"><img src={CheckCircle} alt="" /></div>
                                        </td>
                                        <td>
                                            <div className="flex justify-center"><img src={CheckCircle} alt="" /></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ color: 'white', textAlign: 'left' }}>Inbuilt CRM</td>
                                        <td>
                                            <div className="flex justify-center"><img src={CheckCircle} alt="" /></div>
                                        </td>
                                        <td>
                                            <div className="flex justify-center"><img src={CheckCircle} alt="" /></div>
                                        </td>
                                        <td>
                                            <div className="flex justify-center"><img src={CheckCircle} alt="" /></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ color: 'white', textAlign: 'left' }}>Import Contact</td>
                                        <td>
                                            <div className="flex justify-center"><img src={CheckCircle} alt="" /></div>
                                        </td>
                                        <td>
                                            <div className="flex justify-center"><img src={CheckCircle} alt="" /></div>
                                        </td>
                                        <td>
                                            <div className="flex justify-center"><img src={CheckCircle} alt="" /></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ color: 'white', textAlign: 'left' }}>Custom Bot Name</td>
                                        <td>
                                            <div className="flex justify-center"><img src={CheckCircle} alt="" /></div>
                                        </td>
                                        <td>
                                            <div className="flex justify-center"><img src={CheckCircle} alt="" /></div>
                                        </td>
                                        <td>
                                            <div className="flex justify-center"><img src={CheckCircle} alt="" /></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ color: 'white', textAlign: 'left' }}>CSV Export</td>
                                        <td>
                                            <div className="flex justify-center"><img src={CheckCircle} alt="" /></div>
                                        </td>
                                        <td>
                                            <div className="flex justify-center"><img src={CheckCircle} alt="" /></div>
                                        </td>
                                        <td>
                                            <div className="flex justify-center"><img src={CheckCircle} alt="" /></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ color: 'white', textAlign: 'left' }}>Email & Chat Support</td>
                                        <td>
                                            <div className="flex justify-center"><img src={CheckCircle} alt="" /></div>
                                        </td>
                                        <td>
                                            <div className="flex justify-center"><img src={CheckCircle} alt="" /></div>
                                        </td>
                                        <td>
                                            <div className="flex justify-center"><img src={CheckCircle} alt="" /></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ color: 'white', textAlign: 'left' }}>Feedback collection</td>
                                        <td>
                                            Web Channel
                                        </td>
                                        <td>
                                            Web Channel
                                        </td>
                                        <td>
                                            Web channel and WhatsApp
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ color: 'white', textAlign: 'left' }}>Maximum Users</td>
                                        <td>
                                            2
                                        </td>
                                        <td>
                                            5
                                        </td>
                                        <td>
                                            10
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ color: 'white', textAlign: 'left' }}>Priority Customer Support</td>
                                        <td>
                                            <div className="flex justify-center"><img src={CheckCircle} alt="" /></div>
                                        </td>
                                        <td>
                                            <div className="flex justify-center"><img src={CheckCircle} alt="" /></div>
                                        </td>
                                        <td>
                                            <div className="flex justify-center"><img src={CheckCircle} alt="" /></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ color: 'white', textAlign: 'left' }}>Department</td>
                                        <td>
                                            <div className="flex justify-center opacity-50"><img src={CheckClose} alt="" /></div>
                                        </td>
                                        <td>
                                            <div className="flex justify-center"><img src={CheckCircle} alt="" /></div>
                                        </td>
                                        <td>
                                            <div className="flex justify-center"><img src={CheckCircle} alt="" /></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ color: 'white', textAlign: 'left' }}>Bulk SMS</td>
                                        <td>
                                            <div className="flex justify-center"> 500 units</div>
                                        </td>
                                        <td>
                                            <div className="flex justify-center">1,500 units</div>
                                        </td>
                                        <td>
                                            <div className="flex justify-center">5,000 units</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ color: 'white', textAlign: 'left' }}>Workspace</td>
                                        <td>
                                            1
                                        </td>
                                        <td>
                                            3
                                        </td>
                                        <td>
                                            5
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ color: 'white', textAlign: 'left' }}>Custom Role Permission</td>
                                        <td>
                                            <div className="flex justify-center opacity-50"><img src={CheckClose} alt="" /></div>
                                        </td>
                                        <td>
                                            <div className="flex justify-center"><img src={CheckCircle} alt="" /></div>
                                        </td>
                                        <td>
                                            <div className="flex justify-center"><img src={CheckCircle} alt="" /></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ color: 'white', textAlign: 'left' }}>Custom SMS Template</td>
                                        <td>
                                            <div className="flex justify-center opacity-50"><img src={CheckClose} alt="" /></div>
                                        </td>
                                        <td>
                                            <div className="flex justify-center"><img src={CheckCircle} alt="" /></div>
                                        </td>
                                        <td>
                                            <div className="flex justify-center"><img src={CheckCircle} alt="" /></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ color: 'white', textAlign: 'left' }}>Mobile App</td>
                                        <td>
                                            <div className="flex justify-center"><img src={CheckCircle} alt="" /></div>
                                        </td>
                                        <td>
                                            <div className="flex justify-center"><img src={CheckCircle} alt="" /></div>
                                        </td>
                                        <td>
                                            <div className="flex justify-center"><img src={CheckCircle} alt="" /></div>
                                        </td>
                                    </tr>
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>



                {/* for mobile */}

                <div data-aos="fade-up" data-aos-easing="ease-out" className="md:hidden flex flex-col justify-center">

                    <div className="gap-8 plan-comparism-mobile-heading heading p-6">
                        <img src={BoxIllustration} className='illustration' alt="" />
                        <h3 className="heading-three text-white mb-6">
                            Plan Comparison
                        </h3>
                        <h6 className="mb-6 text-white heading-six">Starter</h6>
                    </div>
                    <div className="gap-8 plan-comparism-mobile-table mt-4 p-6">
                        <img src={BoxIllustration} className='illustration' alt="" />

                        <table className='w-full text-white'>
                            <thead>
                                <th className='desc-col' style={{ width: '70%' }}></th>
                               
                            </thead>

                            <tbody>
                                
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Price</td>
                                    <td className='primary-100 text-right' style={{ fontSize: 15 }}>6,500 NGN</td>
                                    
                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Reporting and Analytics</td>
                                    <td>
                                        <div className="flex justify-end"><img src={CheckCircle} alt="" /></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Activity Logs</td>
                                    <td>
                                        <div className="flex justify-end"><img src={CheckCircle} alt="" /></div>
                                    </td>

                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Inbuilt CRM</td>
                                    <td>
                                        <div className="flex justify-end"><img src={CheckCircle} alt="" /></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Import Contact</td>
                                    <td>
                                        <div className="flex justify-end"><img src={CheckCircle} alt="" /></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Custom Bot Name</td>
                                    <td>
                                        <div className="flex justify-end"><img src={CheckCircle} alt="" /></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>CSV Export</td>
                                    <td>
                                        <div className="flex justify-end"><img src={CheckCircle} alt="" /></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Email & Chat Support</td>
                                    <td>
                                        <div className="flex justify-end"><img src={CheckCircle} alt="" /></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Feedback collection</td>
                                    <td className='text-white text-right'>
                                        Web Channel
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Maximum Users</td>
                                    <td className='text-white text-right'>
                                        2
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Priority Customer Support</td>
                                    <td>
                                        <div className="flex justify-end"><img src={CheckCircle} alt="" /></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Bulk SMS</td>
                                    <td>
                                        <div className="flex justify-end">500 units </div>
                                    </td>

                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Workspace</td>
                                    <td className='text-white text-right'>
                                        1
                                    </td>

                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Custom Role Permission</td>
                                    <td>
                                        <div className="flex justify-end opacity-50"><img src={CheckClose} alt="" /></div>
                                    </td>

                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Custom SMS Template</td>
                                    <td>
                                        <div className="flex justify-end opacity-50"><img src={CheckClose} alt="" /></div>
                                    </td>

                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Mobile App</td>
                                    <td>
                                        <div className="flex justify-end opacity-50"><img src={CheckClose} alt="" /></div>
                                    </td>

                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Dedicated Account Manager</td>
                                    <td>
                                        <div className="flex justify-end opacity-50"><img src={CheckClose} alt="" /></div>
                                    </td>

                                </tr>

                            </tbody>

                        </table>

                    </div>



                    <div className="gap-8 plan-comparism-mobile-heading heading mt-16 p-6">
                        <img src={BoxIllustration} className='illustration' alt="" />
                        <h3 className="heading-three text-white mb-6">
                            Plan Comparison
                        </h3>
                        <h6 className="mb-6 text-white heading-six">Growth</h6>
                    </div>

                    <div className="gap-8 plan-comparism-mobile-table mt-4 p-6">
                        <img src={BoxIllustration} className='illustration' alt="" />

                        <table className='w-full text-white'>
                            <thead>
                                <th className='desc-col' style={{ width: '70%' }}></th>
                            </thead>

                            <tbody>
                                <tr className='mb-4 '>
                                    <td className='desc-col' style={{ color: 'white', textAlign: 'left' }}>Price</td>
                                    <td className='text-white text-right'>15,000NGN</td>
                                    
                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Reporting and Analytics</td>
                                    <td>
                                        <div className="flex justify-end"><img src={CheckCircle} alt="" /></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Activity Logs</td>
                                    <td>
                                        <div className="flex justify-end"><img src={CheckCircle} alt="" /></div>
                                    </td>

                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Inbuilt CRM</td>
                                    <td>
                                        <div className="flex justify-end"><img src={CheckCircle} alt="" /></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Import Contact</td>
                                    <td>
                                        <div className="flex justify-end"><img src={CheckCircle} alt="" /></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Custom Bot Name</td>
                                    <td>
                                        <div className="flex justify-end"><img src={CheckCircle} alt="" /></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>CSV Export</td>
                                    <td>
                                        <div className="flex justify-end"><img src={CheckCircle} alt="" /></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Email & Chat Support</td>
                                    <td>
                                        <div className="flex justify-end"><img src={CheckCircle} alt="" /></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Feedback collection</td>
                                    <td className='text-white text-right'>
                                        Web Channel
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Maximum Users</td>
                                    <td className='text-white text-right'>
                                        5
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Priority Customer Support</td>
                                    <td>
                                        <div className="flex justify-end"><img src={CheckCircle} alt="" /></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Bulk SMS</td>
                                    <td>
                                        <div className="flex justify-end">1,500 units</div>
                                    </td>

                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Workspace</td>
                                    <td className='text-white text-right'>
                                        3
                                    </td>

                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Custom Role Permission</td>
                                    <td>
                                        <div className="flex justify-end"><img src={CheckCircle} alt="" /></div>
                                    </td>

                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Department</td>
                                    <td>
                                        <div className="flex justify-end"><img src={CheckCircle} alt="" /></div>
                                    </td>

                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Custom SMS Template</td>
                                    <td>
                                        <div className="flex justify-end"><img src={CheckCircle} alt="" /></div>
                                    </td>

                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Mobile App</td>
                                    <td>
                                        <div className="flex justify-end"><img src={CheckCircle} alt="" /></div>
                                    </td>

                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Dedicated Account Manager</td>
                                    <td>
                                        <div className="flex justify-end opacity-50"><img src={CheckClose} alt="" /></div>
                                    </td>

                                </tr>

                            </tbody>

                        </table>

                    </div>


                    <div className="gap-8 plan-comparism-mobile-heading heading mt-16 p-6">
                        <img src={BoxIllustration} className='illustration' alt="" />
                        <h3 className="heading-three text-white mb-6">
                            Plan Comparison
                        </h3>
                        <h6 className="mb-6 text-white heading-six">Pro</h6>
                    </div>

                    <div className="gap-8 plan-comparism-mobile-table mt-4 p-6">
                        <img src={BoxIllustration} className='illustration' alt="" />

                        <table className='w-full text-white'>
                            <thead>
                                <th className='desc-col' style={{ width: '70%' }}></th>
                            </thead>

                            <tbody>
                                <tr className='mb-4 '>
                                    <td className='desc-col' style={{ color: 'white', textAlign: 'left' }}>Setup fee</td>
                                    <td className='text-white text-right'>25,200NGN</td>

                                </tr>

                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Reporting and Analytics</td>
                                    <td>
                                        <div className="flex justify-end"><img src={CheckCircle} alt="" /></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Activity Logs</td>
                                    <td>
                                        <div className="flex justify-end"><img src={CheckCircle} alt="" /></div>
                                    </td>

                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Inbuilt CRM</td>
                                    <td>
                                        <div className="flex justify-end"><img src={CheckCircle} alt="" /></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Import Contact</td>
                                    <td>
                                        <div className="flex justify-end"><img src={CheckCircle} alt="" /></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Custom Bot Name</td>
                                    <td>
                                        <div className="flex justify-end"><img src={CheckCircle} alt="" /></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>CSV Export</td>
                                    <td>
                                        <div className="flex justify-end"><img src={CheckCircle} alt="" /></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Email & Chat Support</td>
                                    <td>
                                        <div className="flex justify-end"><img src={CheckCircle} alt="" /></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Feedback collection</td>
                                    <td className='text-white text-right'>
                                        Web channel and WhatsApp
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Maximum Users</td>
                                    <td className='text-white text-right'>
                                        10
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Priority Customer Support</td>
                                    <td>
                                        <div className="flex justify-end"><img src={CheckCircle} alt="" /></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Bulk SMS</td>
                                    <td>
                                        <div className="flex justify-end">5,000 units</div>
                                    </td>

                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Workspace</td>
                                    <td className='text-white text-right'>
                                        5
                                    </td>

                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Custom Role Permission</td>
                                    <td>
                                        <div className="flex justify-end"><img src={CheckCircle} alt="" /></div>
                                    </td>

                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Department</td>
                                    <td>
                                        <div className="flex justify-end"><img src={CheckCircle} alt="" /></div>
                                    </td>

                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Custom SMS Template</td>
                                    <td>
                                        <div className="flex justify-end"><img src={CheckCircle} alt="" /></div>
                                    </td>

                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Mobile App</td>
                                    <td>
                                        <div className="flex justify-end"><img src={CheckCircle} alt="" /></div>
                                    </td>

                                </tr>
                                <tr>
                                    <td style={{ color: 'white', textAlign: 'left' }}>Dedicated Account Manager</td>
                                    <td>
                                        <div className="flex justify-end"><img src={CheckCircle} alt="" /></div>
                                    </td>

                                </tr>

                            </tbody>

                        </table>

                    </div>
                </div>

                <div className='container mx-auto pt-40 mb-20 px-4 md:pr-8 section-six'>
                    <h2 data-aos="fade-up" className="heading-four text-left md:text-center">Frequently Asked Questions</h2>

                    <div className="md:flex gap-8 mt-12 md:mt-20">
                        <div data-aos="fade-up" className='md:w-2/4'>
                            <Accordion title="How does the free trial work?" desc="The free trial allows you to explore our service without any initial cost. This typically lasts for a duration of 14 days." />
                            <Accordion title="Can I upgrade or downgrade my plan?" desc="Yes, you can easily upgrade or downgrade your plan to better align with your needs. We offer flexibility to accommodate your changing requirements. Whether you initially signed up for the starter plan and need more features, you can make these adjustments through your account settings. " />
                        </div>

                        <div data-aos="fade-up" className='md:w-2/4'>
                            <Accordion title="What happens after the free trial ends?" desc="When your free trial period concludes, your access to the features provided during the trial will typically expire. You will no longer be able to use those features without upgrading to a paid plan. " />
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}

export default Pricing